import { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SitesContext } from 'context/SitesContextProvider'

import ModelMeasurements from 'components/ModelMeasurements'
import CloudinarySolarnetImage from 'components/CloudinarySolarnetImage'
import AbbreviatedNumber from 'components/AbbreviatedNumber'
import Link from 'components/Link'

import s from './ModelListModel.module.css'

function ModelListModel({
  group,
  firstName,
  lastName,
  image,
  hideMeasurements,
  measurements,
  instagramCount,
  showFollowers,
  href,
  withBoard,
  className,
}) {
  const { currentSite } = useContext(SitesContext)

  const setInstagram = (igClass) => {
    if (!instagramCount) return null
    return (
      <span
        className={classNames(s.instagram, igClass, {
          [s.instagramVisible]: showFollowers === true,
        })}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className={s.instagramIcon}
        >
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </svg>
        <AbbreviatedNumber>{instagramCount}</AbbreviatedNumber>
      </span>
    )
  }

  return (
    <Link
      className={classNames(s.container, className)}
      type="BoardModel"
      href={href}
      type={withBoard ? 'BoardModel' : 'Model'}
    >
      {image && (
        <div className={s.imgWrap}>
          <CloudinarySolarnetImage
            className={s.img}
            image={[
              {
                url: image.sourceUrl?.replace('/web_start_picture', ''),
                params: 'w_400,ar_3:4,c_fit,g_north,f_auto',
                alt: `${firstName} ${lastName}`,
              },
              // {
              //   url: image.sourceUrl?.replace('/web_start_picture', ''),
              //   params: 'w_320,ar_3:4,c_fit,g_north,f_auto',
              //   alt: `${firstName} ${lastName}`,
              // },
            ]}
          />
          {!hideMeasurements && (
            <div className={s.overlay}>
              {group !== 'talent' && (
                <ModelMeasurements
                  className={s.measurements}
                  listClassName={s.measurementsList}
                  itemClassName={s.measurement}
                  valueClassName={s.measurementValue}
                  measurements={measurements}
                  units={currentSite?.units}
                  shoeSizeUnits={currentSite?.shoeSizeUnits}
                  male={group === 'men'}
                  noToggle
                />
              )}
              {setInstagram()}
            </div>
          )}
        </div>
      )}
      {firstName && (
        <span
          className={classNames(s.name, {
            [s.nameNoPadding]: hideMeasurements === true,
          })}
        >
          {firstName}
        </span>
      )}
      {lastName && lastName !== '0' && (
        <span
          className={classNames(s.name, {
            [s.nameNoPadding]: hideMeasurements === true,
          })}
        >
          {lastName}
        </span>
      )}
      {setInstagram(s.instagramGrid)}
    </Link>
  )
}

ModelListModel.propTypes = {
  group: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  image: PropTypes.object,
  hideMeasurements: PropTypes.bool,
  measurements: PropTypes.array,
  instagramCount: PropTypes.number,
  href: PropTypes.string,
  withBoard: PropTypes.bool,
  showFollowers: PropTypes.bool,
}
ModelListModel.defaultProps = {
  group: '',
  firstName: '',
  lastName: '',
  image: {},
  hideMeasurements: false,
  measurements: [],
  instagramCount: 0,
  href: '',
  withBoard: false,
  showFollowers: false,
}

export default ModelListModel
