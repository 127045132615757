import { useContext } from 'react'

import Link from 'components/Link'

import { SitesContext } from 'context/SitesContextProvider'
import { GlobalDispatchContext } from 'context/GlobalContextProvider'
import closeFlashMessage from 'actions/flashMessage/close'

import s from './HardcodedFlashMessage.module.css'

function HardcodedFlashMessage() {
  const { currentSite } = useContext(SitesContext)
  const dispatch = useContext(GlobalDispatchContext)
  return (
    <div className={s.container}>
      <div className={s.content}>
        Please be aware that individuals are falsely claiming to be scouts for
        Select Model Management.{' '}
        {currentSite && (
          <Link type="Page" href="beware-of-scammers">
            Click here for more info.
          </Link>
        )}
      </div>
      <button
        type="button"
        title="Close overlay"
        className={s.closeButton}
        onClick={() => dispatch(closeFlashMessage())}
      >
        Close
      </button>
    </div>
  )
}

export default HardcodedFlashMessage
