import { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './MenuSearchMeasurements.module.css'

import { SitesContext } from 'context/SitesContextProvider'

import SelectField from 'components/SelectField'

function camelCaseToNormal(str) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (part) => part.toUpperCase())
}

function toFeet(n) {
  const realFeet = (n * 0.3937) / 12
  const feet = Math.floor(realFeet)
  const inches = Math.round((realFeet - feet) * 12)
  return `${feet}" ${inches}'`
}

function MenuSearchMeasurements({
  handleCloseMeasurements,
  handleMenuTitle,
  handleSubmit,
  loading,
  clearFilters,
  hide,
  className,
}) {
  const { currentSite } = useContext(SitesContext)
  const [units, setUnits] = useState(currentSite?.units || 'imperial')
  const [gender, setGender] = useState('female')
  const [fieldValues, setFieldValues] = useState({})

  useEffect(() => {
    clearFilters === true && setFieldValues({})
  }, [clearFilters])

  const handleUnits = (unit) => setUnits(unit)

  const measurementOptions = (attr, oppositeValue = null, max = false) => {
    const isDisabled = (value) => {
      if (!oppositeValue) return false
      return max ? value < oppositeValue : value > oppositeValue
    }

    switch (attr) {
      case 'height':
        return Array.from(Array(14), (_, x) => {
          const value = 164 + x * 2
          return {
            value,
            label: units === 'metric' ? `${value} CM` : `${toFeet(value)}'`,
            disabled: isDisabled(value),
          }
        })
      case 'bust':
        return Array.from(Array(51), (_, x) => {
          const value = 70 + x
          return {
            value,
            label:
              units === 'metric'
                ? `${value} CM`
                : `${Math.round(value * 0.3937)}'`,
            disabled: isDisabled(value),
          }
        })
      case 'waist':
        return Array.from(Array(44), (_, x) => {
          const value = 57 + x
          return {
            value,
            label:
              units === 'metric'
                ? `${value} CM`
                : `${Math.round(value * 0.3937)}'`,
            disabled: isDisabled(value),
          }
        })
      case 'hips':
        return Array.from(Array(72), (_, x) => {
          const value = 69 + x
          return {
            value,
            label:
              units === 'metric'
                ? `${value} CM`
                : `${Math.round(value * 0.3937)}'`,
            disabled: isDisabled(value),
          }
        })
      case 'sleeve':
      case 'neck':
      case 'inseam':
        return Array.from(Array(180), (_, x) => {
          const value = 1 + x
          return {
            value,
            label:
              units === 'metric'
                ? `${value} CM`
                : `${Math.round(value * 0.3937)}'`,
            disabled: isDisabled(value),
          }
        })
      case 'suitSize':
        return Array.from(Array(9), (_, x) => {
          const value = 42 + x * 2
          return {
            value,
            label: units === 'metric' ? value : 32 + x * 2,
            disabled: isDisabled(value),
          }
        })
      case 'suitCut':
        return ['X Short', 'Short', 'Regular', 'Long', 'X Long', 'XX Long']
      case 'cup':
        return ['AA', 'A', 'B', 'B/C', 'C', 'D', 'DD', 'E', 'F']
      case 'shoeSize':
      default:
        return Array.from(Array(22), (_, x) => {
          const value = 30 + x
          return {
            value,
            label: value,
            disabled: isDisabled(value),
          }
        })
    }
  }

  const minMaxFields =
    gender === 'male'
      ? [
          'height',
          'waist',
          'hips',
          'inseam',
          'neck',
          'sleeve',
          'suitSize',
          'shoeSize',
        ]
      : ['height', 'bust', 'waist', 'hips', 'cup', 'shoeSize']

  const handleAttrChange = (attr, val) => {
    const fields = { ...fieldValues }
    fields[attr] = val
    setFieldValues(fields)
  }

  return (
    <form
      className={classNames(s.container, className, {
        [s.hide]: hide === true,
      })}
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit({
          gender,
          ...fieldValues,
        })
      }}
    >
      <h2 className={s.title}>Search models</h2>
      <h3 className={s.subtitle}>By measurements</h3>
      <div className={s.measurements}>
        <div className={s.measurement}>
          <span
            className={classNames(
              s.measurementTitle,
              s.measurementTitleDesktop
            )}
          >
            Division
          </span>
          <div className={classNames(s.measurementCol, s.measurementColGender)}>
            <input
              id="womens-division"
              className={s.measurementRadio}
              type="radio"
              name="division"
              checked={gender === 'female'}
              onChange={(e) => {
                setGender('female')
              }}
            />
            <label htmlFor="womens-division" className={s.measurementLabel}>
              Women
            </label>
          </div>
          <div className={classNames(s.measurementCol, s.measurementColGender)}>
            <input
              id="mens-division"
              className={s.measurementRadio}
              type="radio"
              name="division"
              checked={gender === 'male'}
              onChange={(e) => {
                setGender('male')
              }}
            />
            <label htmlFor="mens-division" className={s.measurementLabel}>
              Men
            </label>
          </div>
        </div>
        <div className={s.units}>
          <button
            type="button"
            className={classNames(s.unit, {
              [s.unitActive]: units === 'metric',
            })}
            onClick={() => handleUnits('metric')}
          >
            CM
          </button>
          <button
            type="button"
            className={classNames(s.unit, {
              [s.unitActive]: units === 'imperial',
            })}
            onClick={() => handleUnits('imperial')}
          >
            INCH
          </button>
        </div>
        {minMaxFields.map((name) => (
          <div key={name} className={s.measurement}>
            <span className={s.measurementTitle}>
              {camelCaseToNormal(name)}
            </span>
            <div className={s.measurementCol}>
              <SelectField
                onChange={handleAttrChange}
                name={`${name}Min`}
                id={`field${name}`}
                options={measurementOptions(name, fieldValues[`${name}Max`])}
                label="From"
                className={s.measurementInput}
                optionsClassName={s.selectOptions}
                value={fieldValues[`${name}Min`]}
              />
            </div>
            <div className={s.measurementCol}>
              <SelectField
                onChange={handleAttrChange}
                name={`${name}Max`}
                options={measurementOptions(
                  name,
                  fieldValues[`${name}Min`],
                  true
                )}
                label="To"
                className={s.measurementInput}
                optionsClassName={s.selectOptions}
                value={fieldValues[`${name}Max`]}
              />
            </div>
          </div>
        ))}
      </div>
      <input
        type="submit"
        className={s.submitBtn}
        disabled={loading}
        value={loading ? 'Loading search data...' : 'Search'}
      />
      <button
        type="button"
        className={s.nameBtn}
        onClick={() => {
          handleCloseMeasurements(), handleMenuTitle('Search by name')
        }}
      >
        Search by name
      </button>
    </form>
  )
}

MenuSearchMeasurements.propTypes = {
  handleCloseMeasurements: PropTypes.func,
  handleMenuTitle: PropTypes.func,
  handleSubmit: PropTypes.func,
  clearFilters: PropTypes.bool,
  hide: PropTypes.bool,
  className: PropTypes.string,
}
MenuSearchMeasurements.defaultProps = {
  handleCloseMeasurements: () => {},
  handleMenuTitle: () => {},
  handleSubmit: () => {},
  clearFilters: false,
  hide: false,
  className: '',
}

export default MenuSearchMeasurements
